import React from "react"
import type { TagProps } from "@chakra-ui/react"
import { Tag } from "@chakra-ui/react"

import type { Governor, Maybe, VoteStats } from "query/graphql"
import { ProposalStatus } from "query/graphql"
import { wasDefeatedByQuorum } from "proposal/helpers/votes"

function ProposalStatusTag({
  status,
  quorum,
  decimals,
  voteStats,
  timelockId,
  governor,
  ...tagProps
}: {
  status: ProposalStatus
  quorum: string
  decimals: number
  governor?: Pick<Governor, "type" | "name">
  timelockId: string | null | undefined
  voteStats?: Maybe<VoteStats[]>
} & TagProps) {
  const isL1ExecutionEnabled = governor?.name === "Arbitrum Core"

  function getLabel({
    status,
    quorum,
    decimals,
    voteStats,
  }: {
    status: ProposalStatus
    quorum: string
    decimals: number
    voteStats?: Maybe<VoteStats[]>
  }) {
    switch (status) {
      case ProposalStatus.Active:
      case ProposalStatus.Extended: // TODO(@nicolas): Should it display a different label?
        return "Active"
      case ProposalStatus.Pending:
        return "Pending"
      case ProposalStatus.Expired:
        return "Expired"
      case ProposalStatus.Defeated:
        if (governor) {
          return wasDefeatedByQuorum(governor, quorum, decimals, voteStats)
            ? "Quorum not reached"
            : "Defeated"
        }

        return "Defeated"
      case ProposalStatus.Executed:
        if (isL1ExecutionEnabled) {
          return "Executed on L2"
        }
      // fallthrough
      case ProposalStatus.Crosschainexecuted:
        return "Executed"
      case ProposalStatus.Queued:
      case ProposalStatus.Pendingexecution:
        return "Pending execution"
      case ProposalStatus.Draft:
        return "Draft"
      case ProposalStatus.Canceled:
        if (governor) {
          return wasDefeatedByQuorum(governor, quorum, decimals, voteStats)
            ? "Quorum not reached"
            : "Canceled"
        }

        return "Canceled"
      case ProposalStatus.Succeeded:
        return timelockId ? "Pending queue" : "Pending execution"
      case ProposalStatus.Vetovoteopen:
        return "Veto vote open"
      case ProposalStatus.Vetoquorummet:
        return "Veto quorum met"
      case ProposalStatus.Vetoed:
        return "Vetoed"
      default:
        console.warn("Unknown proposal status", status)

        return "Active"
    }
  }

  function getColor(status: ProposalStatus) {
    switch (status) {
      case ProposalStatus.Active:
      case ProposalStatus.Extended:
      case ProposalStatus.Pending:
        return "purple.500"
      case ProposalStatus.Expired:
      case ProposalStatus.Defeated:
      case ProposalStatus.Vetoed:
        return "red.500"
      case ProposalStatus.Executed:
      case ProposalStatus.Crosschainexecuted:
      case ProposalStatus.Succeeded:
      case ProposalStatus.Queued:
      case ProposalStatus.Pendingexecution:
        return "teal.600"
      case ProposalStatus.Canceled:
      case ProposalStatus.Draft:
        return "gray.500"
      default:
        return "purple.500"
    }
  }

  function getBackground(status: ProposalStatus) {
    switch (status) {
      case ProposalStatus.Active:
      case ProposalStatus.Extended:
      case ProposalStatus.Pending:
        return "purple.100"
      case ProposalStatus.Expired:
      case ProposalStatus.Defeated:
      case ProposalStatus.Vetoed:
        return "red.100"
      case ProposalStatus.Executed:
      case ProposalStatus.Crosschainexecuted:
      case ProposalStatus.Succeeded:
      case ProposalStatus.Queued:
      case ProposalStatus.Pendingexecution:
        return "teal.50"
      case ProposalStatus.Canceled:
      case ProposalStatus.Draft:
        return "gray.100"
      default:
        return "purple.100"
    }
  }

  return (
    <Tag bg={getBackground(status)} color={getColor(status)} {...tagProps}>
      {getLabel({ status, quorum, decimals, voteStats })}
    </Tag>
  )
}
export default ProposalStatusTag
